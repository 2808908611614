import React, { useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import { Popup } from "@urbica/react-map-gl";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import Card from "../../components/Cards";
import Map from "../../components/Map";
import Marked from "../../components/Map/Marker";
import requests from "../../services/requests"
import api from "../../services/api";

const { io } = require("socket.io-client");

const LogisticPage = () => {
    const socket = io("https://realtime.vendemmia.com.br");

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [autoCenter, setAutoCenter] = useState(false);
    const listRef = useRef([]);
    const [list, setList] = useState([]);
    const listByUserRef = useRef({});
    const [listByUser, setListByUser] = useState({});
    const listByVehicleRef = useRef({});
    const [listByVehicle, setListByVehicle] = useState({});
    const listByTripRef = useRef({});
    const [listByTrip, setListByTrip] = useState({});
    const [checkpoints, setCheckpoints] = useState({});
    const [cars, setCars] = useState({});
    const [tempCars, setTempCars] = useState({});
    const getRoutes = () => {
        if (checkpoints.length == 0 || checkpoints.length == undefined) {
            api.get("/adm/driver-freight/day", {
                headers: {
                    Authorization: "Bearer " + window.localStorage.getItem("session-token"),
                },
            }).then(response => {
                const tempCars = {};
                let i = 0;
                const checkpoints = response.data.reduce((accumulator, actualTrip) => {
                    const updatedCheckpoints = { ...accumulator, ...actualTrip["checkpoints"] };
                    const firstCheckpoint = actualTrip["checkpoints"][0];

                    tempCars[i] = firstCheckpoint;
                    i++;

                    return updatedCheckpoints;
                }, {});

                // atualiza o estado de cars somente após concluir a iteração sobre todos os elementos de response.data
                setCars(prevCars => ({ ...prevCars, ...tempCars }));
                setCheckpoints(checkpoints);
            });

        }
        // console.log(checkpoints);
    };
    useEffect(() => {
        getRoutes();
        // console.log(checkpoints);
    }, []);
    const addToList = useCallback(data => {
        if (data === null || data.length === 0) {
            return;
        }


        let all = [...listRef.current];
        all.push(data);

        listRef.current = all;
        setList(all);

        if (data['user'] && data['user'].length > 0) {
            let byUser = { ...listByUserRef.current };

            if (!(data['user'] in byUser)) {
                byUser[data['user']] = [];
            }
            byUser[data['user']].push(data);

            listByUserRef.current = byUser;
            setListByUser(byUser);
        }

        if (data['vehicle'] && data['vehicle'].length > 0) {
            let byVehicle = { ...listByVehicleRef.current };

            if (!(data['vehicle'] in byVehicle)) {
                byVehicle[data['vehicle']] = [];
            }
            byVehicle[data['vehicle']].push(data);

            listByVehicleRef.current = byVehicle;
            setListByVehicle(byVehicle);
        }

        if (data['trip'] && data['trip'].length > 0) {
            let byTrip = { ...listByTripRef.current };

            if (!(data['trip'] in byTrip)) {
                byTrip[data['trip']] = [];
            }
            byTrip[data['trip']].push(data);

            listByTripRef.current = byTrip;
            setListByTrip(byTrip);
        }
    }, []);

    useEffect(() => {
        if (!autoCenter) {
            return;
        }

        let coordinates = [];
        Object.keys(listByUser).map((key) => {
            return (
                coordinates.push(listByUser[key][listByUser[key].length - 1])
            )
        });

        let centered = centroid(coordinates);
        setViewport({
            latitude: centered.latitude,
            longitude: centered.longitude,
            zoom: 5,
        });
    }, [listByUser, autoCenter]);

    const centroid = coordinates => {
        let latXTotal = 0;
        let latYTotal = 0;
        let lonDegreesTotal = 0;

        if (coordinates.length === 0) {
            return {
                latitude: -14.235004,
                longitude: -51.925282,
            };
        }

        let currentLatLong;
        for (let i = 0; (currentLatLong = coordinates[i]); i++) {
            if (!currentLatLong.latitude || !currentLatLong.longitude) {
                continue;
            }
            const latDegrees = currentLatLong.latitude;
            const lonDegrees = currentLatLong.longitude;
            const latRadians = (Math.PI * latDegrees) / 180;
            latXTotal += Math.cos(latRadians);
            latYTotal += Math.sin(latRadians);

            lonDegreesTotal += lonDegrees;
        }
        const finalLatRadians = Math.atan2(latYTotal, latXTotal);
        const finalLatDegrees = (finalLatRadians * 180) / Math.PI;
        const finalLonDegrees = lonDegreesTotal / coordinates.length;

        return {
            latitude: finalLatDegrees,
            longitude: finalLonDegrees,
        };
    };

    useEffect(() => {
        socket.on("connect", () => {
            setIsConnected(true);
        });

        socket.on("position", (raw) => {
            if (raw.length === 0) {
                return;
            }
            // console.log(raw);
            raw.identifier = raw.trip;
            raw.user = raw.trip;
            addToList(raw);
            return;
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
        });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('position');
        };
    });

    const [onClicked, setOnClicked] = useState({});

    const handleClickedPopup = key => {
        let aux = { ...onClicked };
        if (aux && aux[key]) {
            aux[key] = !aux[key];
        } else {
            aux[key] = true;
        }

        setOnClicked(aux);
    };

    const [viewport, setViewport] = useState({
        latitude: -14.235004,
        longitude: -51.925282,
        zoom: 2,
    });

    return (
        <Layout>
            <SEO title="Logística - Mapa de viagens" />
            <div className="p-4">
                <Card>
                    <div className="flex-wrap ">
                        <h5 className="uppercase mb-2 float-left">
                            Mapa de viagens {isConnected && <small>(em tempo real)</small>}
                        </h5>

                        <button
                            className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right"
                            onClick={() => setAutoCenter(!autoCenter)}
                        >
                            {autoCenter ? 'Auto-centralizando em todas as viagens' : 'Não centralizando nas viagens'}
                        </button>
                    </div>
                    <Map viewport={viewport} setViewport={setViewport} style={{ width: "100%", height: "650px" }}>
                        {cars &&
                            Object.keys(cars).map(
                                (checKey) => {
                                    if (cars[checKey] != undefined) {
                                        return (
                                            <Marked
                                                key={checKey}
                                                latitude={cars[checKey].latitude}
                                                longitude={cars[checKey].longitude}
                                                type="car"
                                                color="roxo_oficial"
                                            />
                                        )

                                    } else {
                                        return;
                                    }
                                }
                            )}
                        {checkpoints &&
                            Object.keys(checkpoints).map(
                                (checKey) => {
                                    return (
                                        <Marked
                                            key={checKey}
                                            latitude={checkpoints[checKey].latitude}
                                            longitude={checkpoints[checKey].longitude}
                                            type="bullet"
                                            data={{
                                                longitude: checkpoints[checKey].longitude,
                                                latitude: checkpoints[checKey].latitude,
                                                time: checkpoints[checKey].date,
                                                speed: (checkpoints[checKey].speed * 3.6).toFixed(2),
                                            }}
                                            color="roxo_oficial"
                                        />
                                    )
                                }
                            )}

                        {listByUser && Object.keys(listByUser).map((key) => {
                            return (<>

                                {listByUser[key].length > 0 && listByUser[key].map((data, key2) => {
                                    return (
                                        <div key={key2}>
                                            <Marked
                                                key={key2}
                                                latitude={data.latitude}
                                                longitude={data.longitude}
                                                type="bullet"
                                                color="roxo_oficial"
                                            />
                                        </div>
                                    );
                                })}
                                <div key={listByUser[key][listByUser[key].length - 1].identifier}>
                                    <Marked
                                        key={listByUser[key][listByUser[key].length - 1].identifier}
                                        latitude={listByUser[key][listByUser[key].length - 1].latitude}
                                        longitude={listByUser[key][listByUser[key].length - 1].longitude}
                                        type="car"
                                        color="roxo_oficial"
                                        onClick={() => handleClickedPopup(listByUser[key][listByUser[key].length - 1].identifier)}
                                    />
                                    {onClicked && onClicked[listByUser[key][listByUser[key].length - 1].identifier] && (
                                        <Popup
                                            className="p-2"
                                            latitude={listByUser[key][listByUser[key].length - 1].latitude}
                                            longitude={listByUser[key][listByUser[key].length - 1].longitude}
                                            closeButton={false}
                                        >
                                            Veículo: {listByUser[key][listByUser[key].length - 1].identifier}
                                            <br />

                                            Data: {moment(listByUser[key][listByUser[key].length - 1].date).format("DD/MM/YYYY")}
                                            <br />

                                            Hora: {moment(listByUser[key][listByUser[key].length - 1].date).format("HH:mm")}
                                            <br />
                                        </Popup>
                                    )}
                                </div>
                            </>);
                        })}
                    </Map>

                    {/*<h6>Por usuário</h6>
                    <ul>
                        {listByUser && Object.keys(listByUser).map((key) => {
                            return (<>
                                <li key={key}>
                                    <b>{key}:</b>
                                    {listByUser[key] && listByUser[key].length > 0 && listByUser[key].map((data, key2) => {
                                        return (
                                            <span key={key2}>
                                                {"("}{data.latitude}, {data.longitude}{"); "}
                                            </span>
                                        );
                                    })}
                                </li>
                            </>);
                        })}
                    </ul>

                    <h6>Por veículo</h6>
                    <ul>
                        {listByVehicle && Object.keys(listByVehicle).map((key) => {
                            return (<>
                                <li key={key}>
                                    <b>{key}:</b>
                                    {listByVehicle[key] && listByVehicle[key].length > 0 && listByVehicle[key].map((data, key2) => {
                                        return (
                                            <span key={key2}>
                                                {"("}{data.latitude}, {data.longitude}{"); "}
                                            </span>
                                        );
                                    })}
                                </li>
                            </>);
                        })}
                    </ul>

                    <h6>Por viagem</h6>
                    <ul>
                        {listByTrip && Object.keys(listByTrip).map((key) => {
                            return (<>
                                <li key={key}>
                                    <b>{key}:</b>
                                    {listByTrip[key] && listByTrip[key].length > 0 && listByTrip[key].map((data, key2) => {
                                        return (
                                            <span key={key2}>
                                                {"("}{data.latitude}, {data.longitude}{"); "}
                                            </span>
                                        );
                                    })}
                                </li>
                            </>);
                        })}
                    </ul>

                    <b>Lista</b>
                    <ul>
                        {list && list.length > 0 && list.map((data, key) => {
                            return (
                                <li key={key}>{data.user}: {data.latitude}, {data.longitude}</li>
                            );
                        })}
                    </ul>*/}
                </Card>
            </div>
        </Layout>
    );
};

export default LogisticPage;
